.root {
  position: relative;
}

.input {
  display: flex;
}

.input > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 18px;
  outline: 0;
  background: transparent;
}

.input > input::-webkit-input-placeholder {
  color: var(--text-color-grey);
  font-weight: normal;
}

.input > input::-moz-placeholder {
  color: var(--text-color-grey);
  font-weight: normal;
}

.input > input:-ms-input-placeholder {
  color: var(--text-color-grey);
  font-weight: normal;
}

.input > input::-ms-input-placeholder {
  color: var(--text-color-grey);
  font-weight: normal;
}

.input > input::placeholder {
  color: var(--text-color-grey);
  font-weight: normal;
}

.error {
  color: var(--error-color);
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}

.invalid {
  border-color: var(--error-color);
}

.invalid ~ img {
  margin-top: -50px !important;
}
