body {
  background-color: var(--background-color) !important;
}

.headerReset {
  background-color: var(--background-color);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
}

.headerReset > h1 {
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.textCenter {
  text-align: center;
}

.root > .pageDesc {
  background-color: #F5F5F7;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root > .success { 
  background-color: var(--background-color);
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.success > h1 {
  margin-top: 80px;
  font-size: 25px;
  font-weight: bold;
}

.success > p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.success > button {
  width: 90%;
  padding: 10px 40px;
  font-size: 18px;
  line-height: 18px;
  background-color: #EA2036;
  border-radius: 4px;
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.pageDesc > h1 {
  font-size: 16px;
  padding: 18px 25px;
  font-weight: bold;
}

.pageDesc > figure {
  align-items: center;
  display: block;
  position: relative;
}

.pageDesc > figure > img {
  width: 150px;
}

.root > .formReset {
  background-color: var(--background-color);
  position: relative;
  margin: 0 auto;
}

.formReset > form {
  display: block;
  width: 100%;
  padding: 30px;
  padding-top: 40px;
}

.formReset > form > div {
  margin-bottom: 50px;
}

.formReset > form > button {
  width: 100%;
  padding: 10px 40px;
  font-size: 18px;
  line-height: 18px;
  background-color: #EA2036;
  border-radius: 4px;
}

.formReset > form > button:disabled {
  background-color: #eee;
  color: #b3b3b3;
}

.formReset > form > div > img {
  position: absolute;
  right: 30px;
  z-index: 99;
  margin-top: -30px;
}

.formReset > form > div > div.invalid ~ img {
  margin-top: -50px;
}

.errorMsg {
  text-align: center;
  color: red;
  font-size: 14px;
  font-weight: 400;
}