* {
  box-sizing: border-box;
  color: var(--text-color);
  font-family: var(--font-family);
  font-weight: 500;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  overflow: hidden;
}

:root {
  --app-header-height: 4.7rem;
  --app-sider-width: 13.4375rem;
  --table-header-color: rgba(0, 0, 0, 0.87);
  --background-color: #FFFFFF;
  --background-color-grey: #F8F9FF;
  --background-color-light: #EEF4FD;
  --border: 0.0625rem solid var(--border-color);
  --border-button: 0.0625rem solid #0F6EB5;
  --border-thin: 0.25px solid rgba(189,189,189, 0.8);
  --border-color: #D9DEE4;
  --border-color-add-1: #D0E563;
  --border-color-add-2: #FF5D6F;
  --border-color-add-3: #3B98DD;
  --border-radius: 0.3125rem;
  --border-radius-large: 0.625rem;
  --box-shadow-small: 0px 1px 20px rgba(0, 0, 0, 0.1);
  --box-shadow-medium: 0px 5px 1rem rgba(74, 74, 74, 0.1);
  --box-shadow-large: 0px 5px 1.25rem rgba(74, 74, 74, 0.2);
  --error-color: #d0021b;
  --font-family: 'Poppins', sans-serif;
  --nav-background: #FFFFFF;
  --nav-border: 1px solid #DFE3E9;
  --primary-color: #0F6EB5;
  --primary-color-dark: #004485;
  --primary-color-light: #90cdff;
  --secondary-color : #ACC23A;
  --primary-variant-color-1: rgba(237, 43, 46, 0.25);
  --primary-variant-color-2: #f8d227;
  --add-color-1:#44AB4A;
  --add-color-2:#EA2036;
  --add-color:#ACC23A;
  --add-color-hover:#dbec81;
  --success-color:#44AB4A;
  --danger-color:#EA2036;
  --primary-variant-color-transparent: rgba(168, 228, 194, 0.15);
  --text-color: #474C55;
  --text-color-grey: #C5D0DE;
  --text-color-light: #bdbdbd;
  --grey-color-1: rgba(0, 0, 0, 0.54);
  --grey-color-2: #a6a6a6;
  --grey-color-3: #F4F8FD;
  --border-color-2: #DFE3E9;
  --black-color:#000000;
  --placeholder-color:#C5D0DE;
  --textarea-length: rgba(0, 0, 0, 0.54);
  font-size: calc(16 * 100vw / 1366);
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
  
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: var(--grey-color-2);
  position: absolute;
}

::-webkit-scrollbar-track {
  box-shadow: inset var(--box-shadow-large);
  border-radius: 25px;
}

